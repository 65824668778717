import { defineStore } from 'pinia'

export const useCarbarStore = defineStore('carbar', {
	namespaced: true,
	state: () => ({
		header: {
			signUpUserType: 'personal',
			showMobileMenu: false,
		},
		message: {
			title: null,
			error: null,
			success: null,
		},
		vehicleCount: {
			subscription: 0,
		},
		disabledDates: {},

		// Campaigns
		campaigns: {},
		isCampaignActive: {},

		// Novated Lease Promo
		novatedLeasePromo: {
			isPreselected: false,
		},
	}),
	getters: {
		showCampaignStatus: (state) => (campaignName) => {
			const campaignStatus = state.isCampaignActive[campaignName]
			return campaignStatus
		},

		isNovatedLeasePreselected: (state) =>
			state.novatedLeasePromo.isPreselected,
	},
	actions: {
		checkCampaignStatus() {
			this.isCampaignActive = {}

			Object.keys(this.campaigns).forEach((campaignName) => {
				const campaign = this.campaigns[campaignName]

				this.isCampaignActive[campaignName] = isCampaignActive(
					campaign.start_date,
					campaign.end_date
				)
			})
		},

		completePreselection() {
			this.novatedLeasePromo.isPreselected = false
		},

		clearMessage() {
			this.message.title = null
			this.message.error = null
			this.message.success = null
		},
	},
	persist: {
		pick: ['isCampaignActive', 'novatedLeasePromo'],
	},
})
