import { fromZonedTime } from 'date-fns-tz'
import { isAfter, isBefore } from 'date-fns'
import { useCarbarStore } from '@/stores/carbar'

export const isCampaignActive = (startDate, endDate) => {
	// Define the AEDT time zone
	const timeZone = 'Australia/Sydney'

	// If startDate or endDate is not provided or is invalid, return false
	if (!startDate && !endDate) {
		return false
	}

	// Create the campaign date in AEDT UTC
	const campaignStartDateStr = startDate
	const campaignEndDateStr = endDate
	const campaignStartDateUTC = fromZonedTime(campaignStartDateStr, timeZone)
	const campaignEndDateUTC = fromZonedTime(campaignEndDateStr, timeZone)

	// Get the current date in UTC
	const nowUTC = new Date()

	// Check if the current date is between campaign start and end date
	return (
		isBefore(campaignStartDateUTC, nowUTC) &&
		isAfter(campaignEndDateUTC, nowUTC)
	)
}

export const getCampaignBadgeInfo = (discountSet, badge) => {
	// Set default values in case tag doesn't match any case
	let badgeText = '$0 Upfront Fee'
	let badgeColor = 'bg-blue-400'

	// If a custom badge is provided, use that as the text
	if (badge) {
		badgeText = badge
	} else {
		// Determine text and badgeColor based on tag
		switch (discountSet.tag) {
			case 'spring_sales':
				badgeText = 'Spring Sale'
				badgeColor = 'bg-orange-400'
				break
			case 'halloween_sales':
				badgeText = '🎃 Halloween Treats!'
				badgeColor = 'bg-orange-400'
				break
			case 'blackfriday_sales':
				badgeText = discountSet.badge
				badgeColor = 'bg-black'
				break
			case 'yearend_sales':
				badgeText = discountSet.badge
				badgeColor =
					'from-[#00A8BB] via-[#0382cc] to-[#0382cc] bg-gradient-[137deg]'
				break
			default:
				// Use the default values set above
				break
		}
	}

	return { badgeText, badgeColor }
}

// To show secondary campaign promotion
// ie. novated lease Tesla promo
export const showSecondaryCampaign = (
	campaignKey = 'novated_lease_tesla_incentive'
) => {
	const carbar = useCarbarStore()
	const showSecondaryCampaign = carbar.showCampaignStatus(campaignKey)
	const isSecondaryCampaignActive = computed(() => showSecondaryCampaign)

	return { isSecondaryCampaignActive }
}
